<template>
  <div class="card-container unselect" @click="clickDetail(card)">
    <div class="card-content">
      <div class="flex-align">
        <div class="card-img" :style="imgStyle"></div>
        <div style="flex:1;padding-left:12px">
          <div class="subtitle6 main margin-top-12">{{ card.product.name }}</div>
          <div class="body5 sub3 ellipsis-2">{{ card.product.simple_desc }}</div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="flex-between">
        <!-- 가격 정보 -->
        <div>
          <span class="body6 sub3">구매일</span>
          <span class="body5-medium sub" style="margin-left:4px">{{ datesFormat(card.created_time, 'date_3') }}</span>
        </div>
        <div :class="`card-status-${card.status}`" v-if="card.status!==undefined">
          <svg v-if="card.status===2" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20 6L9 17L4 12" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>{{ statusText(card.status) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CardOrderService",
    props: {
      card: {
        type: Object
      }
    },
    created() {
    },
    data() {
      return {
        tagLen: 0
      }
    },
    watch: {

    },
    computed: {
      imgStyle() {
        return `backgroundImage: url(${this.card.product.img})`;
      }
    },
    methods: {
      statusText(status) {
        const arr = {
          0: '추가',
          1: '신청 중',
          2: '이용 중',
          4: '안내 완료',
          5: '결제 완료',
          6: '작업 중'
        };

        return arr[status];
      },
      discountText(product) {
        let text = '';
        if (this.isDiscount(product)) {
          if (product.price.discount_rate !== 0 && product.price.discount_type === 0) {
            text = product.price.discount_rate + '%'
          } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
            text = product.price.discount_price + '원';
          }
        }
        return text;
      },
      // 상품 가격
      discountedPrice(product) {
        let total = 0;
        if (this.isDiscount(product)) {
          if (product.price.discount_rate !== 0 && product.price.discount_type === 0) {
            total = product.price.price * (1 - (product.price.discount_rate / 100))
          } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
            total = product.price.price - product.price.discount_price;
          }
        } else total = product.price.price;
        return total;
      },
      imgField(img) {
        let style = {
          backgroundImage: `url(${this.image(img)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top'
        };
        return style;
      },
      clickDetail(card) {
        this.routeGa('',`추가서비스 구매내역 카드(${card.name})`, card.name);
        this.$router.push(`detail?id=${card.product.id}`)
      },
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .card-container
    background-color white
    border-radius 8px
    border 1px solid $gray2

  .card-img
    width 60px
    height 60px
    border-radius 10px
    background-color $gray3
    display flex
    align-items center
    justify-content center
    background-repeat no-repeat
    background-size cover
    background-position center

  .card-content
    padding 20px

  .tag
    border 1px solid $gray1
    border-radius 50px
    padding 2px 8px
    margin-right 4px

  .card-status-0
  .card-status-1
  .card-status-2
  .card-status-4
  .card-status-5
  .card-status-6
    border-radius 50px
    padding 0 20px
    line-height 32px
    height 32px

  .card-status-0
    background-color $primary
    color white
  .card-status-1
  .card-status-4
  .card-status-5
  .card-status-6
    background-color $primary-light2
    color $primary
  .card-status-2
    background-color $main
    color white
    svg
      margin-right 4px
      width 12px
      height 12px

  .divider
    width 100%
    height 1px
    background-color $gray2
    margin 12px 0

</style>
