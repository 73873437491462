<template>
  <div>
    <div class="pc banner-wrapper" :style="wrapperStyle">
      <img v-if="banner" class="banner" :src="banner.imgs[0]" @click="clickBanner(0)">
    </div>
    <div class="mobile banner-wrapper">
      <img v-if="banner" class="banner" :src="banner.imgs[0]" @click="clickBanner(0)">
    </div>
  </div>
</template>

<script>
  export default {
    name: "BannerMarket",
    created() {
      this.getBanner();
    },
    data() {
      return {
        banner: undefined,
        width: 0
      }
    },
    methods: {
      getBanner() {
        this.$axios.get('public/banner/1').then(res=>{
          this.banner = res.data;
        });
      },
      clickBanner(idx) {
        let path = this.banner.links[idx];
        if(path === '') {
          return;
        }
        if(path.indexOf('app_market')>-1 && this.$route.path === '/market/list') {
          path = path.replace('app_market', 'market');
        }
        if(path.indexOf('http')===0) {
          location.href = path;
        } else {
          this.$router.push(path);
        }
      }
    },
    computed: {
      wrapperStyle() {
        return 'width:1200px;margin:0 auto';
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .banner-wrapper
    padding 24px
    padding-bottom 0
    img
      width 100%

</style>
