<template>
  <div class="text-left">
    <div class="flex-between" style="margin-bottom:64px">
      <div class="size-21" style="color:#363636">앱 마켓 이용 플랜 수정</div>
      <img src="/static/icon/fi_close_outline.svg" class="svg-sub2 unselect" @click="$emit('close')">
    </div>

    <template>
      <!-- 결제 내역 -->
      <div class="margin-bottom-8 terminate-modal-title">
        이용 중인 서비스
        <span v-if="terminateType===1"> - 결제 완료 내역</span>
        <span v-else-if="terminateType===2"> - 다음 결제일 청구 예정 내역</span>
      </div>
      <div class="margin-bottom-24 terminate-modal-content">
        <div v-if="terminateType===0">{{ datesFormat(card.free_date, 'date_3') }}까지 무료 체험</div>
        <div v-else-if="terminateType===1">지금 해지 해도 아래 이용기간까지 구매한 서비스를 계속 이용할 수 있습니다.</div>
        <div v-else-if="terminateType===2">{{ datesFormat(card.next_date, 'date_3') }} 에 결제 예정 <br>
          지금 중지 하면 아래 항목은 중지일 기준으로 정산되고 더 이상 청구되지 않습니다.</div>
      </div>

      <table class="table amt-table" style="width:100%">
        <thead>
        <tr>
          <th class="col-1">구매일</th>
          <th class="col-1">결제일</th>
          <th class="col-1">구분</th>
          <th class="col-1">내용</th>
          <th class="col-3"></th>
          <th class="col-1">플랜</th>
          <th class="col-3">서비스 이용기간</th>
          <th class="col-1">금액</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{ datesFormat(card.created_time, 'date_3') }}</td>
          <td>{{ payDay }}</td>
          <td>{{ card.product.category1_name }}</td>
          <td>{{ card.product.category2_name }}</td>
          <td>{{ card.product.name }}</td>
          <td>{{ computedPlan }}</td>
          <td>{{ datesFormat(card.start_date, 'date_3') }}~{{ datesFormat(card.end_date, 'date_3') }}</td>
          <td>{{ isFree ? 0 : card.subscribe_price | currency }}</td>
        </tr>
        </tbody>
      </table>

      <div class="flex-end" style="padding:20px 20px 0 20px">
        <div style="font-weight:500;color:#363636;font-size:11px">이용 중인 서비스 결제 금액</div>
        <div class="margin-left-20" style="font-weight:400;color:#363636;font-size:11px">{{ isFree ? 0 : card.subscribe_price | currency }}</div>
      </div>

      <div class="margin-bottom-20" style="color:#363636;font-weight:500;font-size:11px;margin-top:100px">
        <div>{{ datesFormat(currentDate, 'date_3') }}(오늘) 이용 중지 신청 시 <span v-if="terminateType>0">정산 청구 내역</span></div>
      </div>

      <!-- 청구할 내역 -->
      <div v-if="terminateType!==1">
        <table class="table amt-table" style="width:100%">
          <thead>
          <tr>
            <th class="col-1">구매일</th>
            <th class="col-1">결제일</th>
            <th class="col-1">구분</th>
            <th class="col-1">내용</th>
            <th class="col-3"></th>
            <th class="col-1">플랜
              <template v-if="terminateType===2">
                <tool-tip style="margin-left:4px"
                          icon="u_question-circle"
                          :iconSize="16"
                          :text="'1개월 플랜: 일할계산 \n' +
              '3개월 플랜: 플랜 정가 기준 일할계산'"/>
              </template>
            </th>
            <th class="col-3">서비스 이용기간</th>
            <th class="col-1">정산금액</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ datesFormat(card.created_time, 'date_3') }}</td>
            <td>{{ payDay }}</td>
            <td>{{ card.product.category1_name }}</td>
            <td>{{ card.product.category2_name }}</td>
            <td>{{ card.product.name }}</td>
            <td>{{ computedPlan }}</td>
            <td>{{ datesFormat(card.start_date, 'date_3') }}~{{ datesFormat(currentDate, 'date_3') }}</td>
            <td>{{ isFree ? 0 : card.subscribe_price | currency }}</td>
          </tr>
          </tbody>
        </table>

        <div class="flex-end" style="padding:20px 20px 0 20px">
          <div style="font-weight:500;color:#363636;font-size:11px">해지 시 오늘 기준 청구 금액</div>
          <div class="margin-left-20" style="font-weight:400;color:#363636;font-size:11px">{{ totalPrice | currency }}</div>
        </div>
      </div>
      <div style="font-weight:400;color:#363636;font-size:11px" v-else>정산 청구 내역이 없습니다.</div>

      <div class="flex-end" style="padding:20px 20px 0 20px">
        <div style="font-weight:500;color:#363636;font-size:11px">총 결제 금액</div>
        <div class="margin-left-20" style="font-weight:400;color:#363636;font-size:11px">
          {{ totalPrice | currency }}
        </div>
      </div>

    </template>

    <div style="margin-top:39px;margin-bottom:39px">
      <lp-check-bool :checkedVal.sync="check"
                     label="플랫폼 팩, 플러그인 등 중지를 원하는 서비스 항목이 오늘 부로 이용 중지 되며, 이로인해 서비스 버그나 작동오류가 발생할 수 있음을 확인하였습니다."></lp-check-bool>
    </div>
    <div class="flex-center" style="margin-bottom:5px;font-size:11px;font-weight:500;color:#363636" v-if="terminateType===2">
      결제 수단
      <span class="margin-left-8">{{ userService.service_order.bill.num }}/{{ userService.service_order.bill.bank }}</span>
    </div>
    <div class="flex-center">
      <button class="button t-modal-btn" @click="stop">
        <span v-if="terminateType===2 && totalPrice > 0">결제 및 이용 중지 완료</span>
        <span v-else>이용 중지</span>
      </button>
    </div>

  </div>
</template>
<script>
  import LpCheckBool from "../module/LpCheckBool";
  import ToolTip from "../component/ToolTip";
  export default {
    name: "AppMarketTerminateModal",
    props: {
      card: {
        type: Object
      }
    },
    components: {ToolTip, LpCheckBool},
    created() {
    },
    data() {
      return {
        check: false
      }
    },
    computed: {
      currentDate() {
        return this.dayjs().format('YYYY-MM-DD')
      },
      calcOneDayPrice() {
        return this.card.subscribe_price / this.card.date_unit / 30
      },
      // 팩,플러그인 이용중지
      terminateType() {
        // 0: 무료기간, 1: 결제후 2: 결제전 (결제해야함)
        let type = 0
        let now = this.dayjs(this.dayjs().format('YYYY-MM-DD'))
        let next_date = this.dayjs(this.card.next_date)
        let end_date = this.dayjs(this.card.end_date)
        if(this.isFree) type = 0
        // 현재일 < 결제일 < 이용기간종료일
        else if(now < next_date && end_date > next_date) type = 2
        else type = 1
        return type
      },
      isFree() {
        /*let now = this.dayjs(this.dayjs().format('YYYY-MM-DD'))
        let free_date = this.dayjs(this.card.free_date)
        let diff = free_date.diff(now,'days')
        return diff >= 0*/
        return this.userService.service_order.trial
      },
      payDay() {
        let text = '';
        // 무료 체험 기간일 경우
        if(this.isFree) {
          text = '-'
        } else {
          text = this.dayjs(this.card.next_date).format('YYYY.MM.DD')
        }
        return text;
      },
      computedPlan() {
        let text = '';
        // 정기 결제일 경우
        if(this.card.is_subscription) {
          text = this.card.date_unit + '개월'
        } else {
          text = '건 결제'
        }
        return text;
      },
      totalPrice() {
        let price = 0;
        if(this.terminateType===2) {
          let start_date = this.dayjs(this.datesFormat(this.card.start_date, 'date_4'))
          // console.log(start_date)
          let day = this.dayjs(this.currentDate).diff(start_date, 'days');
          price = day * this.calcOneDayPrice
          // price = this.card.subscribe_price;
        }
        return price;
      }
    },
    methods: {
      stop() {
        if(!this.check) {
          this.toast('주의사항에 체크한 뒤 진행해주세요.')
          return;
        }
        this.$emit('stop')
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .amt-table th,
  .amt-table td
    text-align center
    padding 6px 0

  .amt-table th
    font-weight 500
    font-size 13px

  .amt-table td
    font-weight 400
    font-size 11px

  .amt-table tr
    border-bottom 1px solid #dddddd

  .amt-table tbody tr:last-child
    border-bottom 1px solid #000000

  .terminate-modal-title
    color #363636
    font-weight 500
    font-size 13px

  .terminate-modal-content
    color #363636
    font-width 400
    font-size 11px

  .t-modal-btn
    width 230px
    height 32px
    background $primary
    color white
    border-radius 3px
    font-size 11px
    font-weight 400
</style>
